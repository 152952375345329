(function($) {

  //Home-title height 100%
  var height = window.innerHeight - 156;
  var width = window.innerWidth;

  if (width > 1024) {
    $('.home-section-one').css('min-height', height + 'px');
  }

})(jQuery);